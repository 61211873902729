// useUETTracking.js
import { useEffect } from 'react';
import BingTracking from 'react-tracking-bing';

const useUETTracking = (dependencies) => {
  useEffect(() => {
    BingTracking.initialize('149006196');
    BingTracking.trackPageview();
  }, dependencies);
};

export default useUETTracking;