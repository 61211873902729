import React, { useState, useEffect } from 'react';
import { Formiz, useForm, FormizStep } from '@formiz/core';
import { useNavigate } from 'react-router-dom';
import { FieldSelect } from '../components/Fields/FieldSelect';
import { isEmail, isMaxNumber, isMinNumber } from '@formiz/validations';
import { CheckCircleIcon } from '@chakra-ui/icons';
import SignatureCanvas from 'react-signature-canvas';
import {
  Radio,
  RadioGroup,
  HStack,
  Divider,
  Flex,
  Spacer,
  Alert,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
} from '@chakra-ui/react';
import {
  Checkbox,
  Heading,
  Button,
  Grid,
  Box,
  Stack,
  AspectRatio,
  Image,
  Center,
  useToast,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
} from '@chakra-ui/react';
import ReactPixel from 'react-facebook-pixel';

import airjustTicket from './airjust-ticket.jpg';

import { FieldInput } from '../components/Fields/FieldInput';
import { PageHeader } from '../components/PageHeader';
import { PageLayout } from '../layout/PageLayout';
import { useToastValues } from '../hooks/useToastValues';
import useUETTracking from '../useUETTracking';
import BingTracking from 'react-tracking-bing';

const fakeDelay = (delay = 200) => new Promise(r => setTimeout(r, delay));

async function fetchData(values) {
  const formURL =
    'https://0apyc838t2.execute-api.eu-west-2.amazonaws.com/prod/submitclaim';
  const requestOptions = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'x-api-key': 'EhBGDT2nBT96tqyFMtc8u4YOGQee5gUM7DOTomgE',
    },
    body: JSON.stringify(values),
  };

  const response = await fetch(formURL, requestOptions);
  const data = await response.json();

  return data;
}

export const Submit = () => {
  const form = useForm({ subscribe: 'form' });
  const { isOpen, onOpen, onClose } = useDisclosure();
  const toastValues = useToastValues();
  const [status, setStatus] = useState('idle');
  const isLoading = status === 'loading' || form.isValidating;
  const [finalInfo, setFinalInfo] = useState({});
  // const [sig, setSig] = useState("");
  const toast = useToast();
  let navigate = useNavigate();

  const person = JSON.parse(localStorage.getItem('airjust-person'));
  const flight = JSON.parse(localStorage.getItem('airjust-flight'));
  const referralCode = localStorage.getItem('airjust-referral');

  const [isFlightCancelled, setIsFlightCancelled] = useState(
    flight.cancelledFlag
  );
  const [flightDelay, setFlightDelay] = useState(flight.delay);
  const [flightIata, setFlightIata] = useState(flight.iata);
  const [flightNumber, setFlightNumber] = useState(flight.number);
  const [flightAirline, setFlightAirline] = useState(flight.airline);
  const [flightDate, setFlightDate] = useState(flight.date);
  const [newArrivalDelay, setNewArrivalDelay] = useState('');
  const [advanceNotice, setAdvanceNotice] = useState('');
  const [value, setValue] = React.useState('1');
  const [agree, setAgree] = React.useState(false);
  const options = {
    autoConfig: true,
    debug: false,
  };
  ReactPixel.init('1300921143958590', null, options);
  ReactPixel.pageView();
  useEffect(() => {
    BingTracking.trackEvent('submitClaimView', '', 'submitClaimView', '0');
    window.gtag('config', 'G-83KZEHBVC0');
    window.gtag('config', 'UA-224099637-1');
  }, []);

  /**
   * Hook into the submitStep method to handle some actions before changing step
   * If you don't need this you can just call the onSubmit={form.submitStep} on your <form>
   */

  const handleSubmitStep = async event => {
    event.preventDefault();
    if (
      !form.currentStep ||
      !form.currentStep?.isValid ||
      !form.currentStep?.name
    ) {
      form.submitStep();
      return;
    }

    setStatus('loading');
    console.log(`Submitting ${form.currentStep?.name}...`); // eslint-disable-line no-console
    await fakeDelay();

    setStatus('success');
    form.submitStep();
  };

  /**
   * Handle the complete form submit
   */
  const handleSubmit = async values => {
    setStatus('loading');

    // if (sig.isEmpty()) {
    //   toast({
    //     title: "Signature Cannot Be Empty",
    //     description: "Please sign so we can represent your claim",
    //     status: 'error',
    //     duration: 10000,
    //     isClosable: true,
    //   })
    //   setStatus('fail');
    //   return

    // }

    console.log('Submitting form...', values); // eslint-disable-line no-console
    values.firstName = person.firstName;
    values.lastName = person.lastName;
    values.email = person.email;
    values.flightNumber = flightNumber;
    values.airline = flightAirline;
    values.flightDate = flightDate;
    values.flightIata = flightIata;
    values.flightDelay = flightDelay;
    values.flightCancelled = isFlightCancelled;
    values.referralCode = referralCode;
    if (newArrivalDelay !== '') {
      values.newArrivalDelay = newArrivalDelay;
    }
    if (advanceNotice !== '') {
      values.advanceNotice = advanceNotice;
    }
    // values.signature = sig.toDataURL('image/png')
    // values.ticketImage = values.ticketImage.file
    console.log(values);
    const data = await fetchData(values);
    console.log(data);
    await setFinalInfo(data);

    console.log(data.statusCode);
    if (data.statusCode !== 200) {
      console.log('fail');
      toast({
        title: data.body,
        description: 'If this issue persists, email us at claims@airjust.co',
        status: 'error',
        duration: 10000,
        isClosable: true,
      });
      setStatus('fail');
    } else {
      console.log('success');
      setStatus('success');

      toast({
        title: 'Submitted',
        description:
          'Thank you for your claim submission, a member of our team will begin working on this and be in touch with you soon',
        status: 'success',
        duration: 10000,
        isClosable: true,
      });
      ReactPixel.track('CompleteRegistration');
      BingTracking.trackEvent('SubmitTicket', '', 'SubmitTicket', '0');
      navigate('../submit-additional-info', { replace: true });
    }
  };

  return (
    <Box>
      <Modal isOpen={isOpen} onClose={onClose} size="xl">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Terms & Conditions</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <p></p>
            <p>
              <b>Section 1</b>
            </p>
            <p>
              1.“Agreement”: an agreement between a Customer and Airjust for
              delivery of claims management entered into after client has read
              and accepted conditions.
            </p>
            <p>
              2.“Airjust”: Toucan Group Ltd, with its registered office at
              Brockhampton, Doggetts Wood Lane, Chalfont St Giles, HP8 4TJ
            </p>
            <p>
              3. “Air Passenger Rights Regulation”: any law, regulation,
              directive, international convention, or similar, EU, national,
              international or regional level, and case law relevant to the
              provision of financial compensation or damages to passengers in
              the event of overbooked, delayed, canceled, disrupted flights or
              luggage claims.
            </p>
            <p>
              4.“E-signature”: electronic signature provided by the client via
              the specified software indicated acceptance of terms and
              conditions, entitling Airjust to pursue claims management on
              behalf of the client- may be used on the acceptance document or
              representation document for the purposes of establishing claim
              assignment and authority to contract-out Claims management to
              third parties.
            </p>
            <p>
              5.“Acceptance document”: the customers signature indicating that
              for the purposes of the pursuit of the claim and payment of monies
              pursuant to this the customer has assigned rights over the claim
              to Airjust.
            </p>
            <p>
              6. “Claim”: any claim brought against an Airjust for the provision
              of financial compensation pursuant to EC-261/2004
            </p>
            <p>
              7.“Representation document”: electronic document signifying that
              the customer has permitted Airjust or any of Airjust third-party
              partners to represent them for the purposes of compensation
              payment.
            </p>
            <p>
              8.“Customer(s)”: any individual who has accepted these terms, and
              is under the representation of Airjust.
            </p>
            <p>
              9. “Flight Compensation”: total amount of money paid by an Airjust
              in relation to a Claim as compensation, settlement, a gesture of
              goodwill, or otherwise, to the Customer or Airjust after the
              Customer has signed the agreement, accepting these Terms. To avoid
              any doubt, Flight Compensation does not include any payments or
              reimbursements of legal fees, court fees, administrative costs of
              collection, penalties or related expenses incurred. Any amounts
              paid by Airjust may, at the discretion of Airjust, be collected
              and used for the benefit of Airjust.
            </p>
            <p>
              10. “Justice as a Service”: is the process of claims management
              engaged in by Airjust of the client’s behalf.
            </p>
            <p>
              11.“Legal Action”: filing a Claim with a court or a government
              body, such as a national enforcement body, or handing over a Claim
              to a third party legal representative- attorney or law firm.
            </p>
            <p>
              13.“EC 261”: Regulation (EC) No. 261/2004 of the European
              Parliament and of the Council dated 11 February 2004, establishing
              common rules on compensation and assistance to passengers in the
              event of denied boarding and of cancellation or long delays of
              flights.
            </p>
            <p></p>
            <p>
              <b>Section 2:</b>
            </p>
            <p></p>
            <p>
              1. The acceptance of these T&amp;Cs, establishing the basis for
              any further documentation- namely the “representation agreement”
              and “acceptance document” and all rights deriving therefrom.
            </p>
            <p>
              2. By entering into an Agreement with Airjust, the Customer/Client
              accepts that they are have the legal capacity to enter into the
              Agreement by free will for themselves, and, where relevant, that
              they have been authorised to so act by any other party on whose
              behalf they seek to act.
            </p>
            <p>
              3. The Customer/Client acknowledges that Airjust only seeks Flight
              Compensation, defined as monetary compensation and stipulated
              within the provisions of EC-261/2004. The Customer/Client is aware
              that Airjust will not accept any vouchers/travel offers or any
              form of remuneration to constitute ‘compensation’
            </p>
            <p>
              4. The Customer/Client affirms that the Claim has not been
              assigned to third parties and no legal dispute is pending or
              expected between the Customer/ Client and the airline/travel
              agency/ flight operator and Airjust in the matter for which the
              initial claim was filed.
            </p>
            <p>
              5. In submitting a claim the Customer/Client confirms that no
              other flight compensation provider/claims management company has
              been similarly authorised to pursue this claim. Prior to the
              submission of any claim with Just Air the Customer/Client accepts
              that they must terminate any existing filed claims
            </p>
            <p>
              6. Submission of a claim is taken as signifying that the client
              will not accept any direct contact or payment from the airline
              from which compensation is being sought pursuant to this
              agreement. In such circumstances the client understands that they
              have a duty to inform Airjust of any such offers provided, and to
              reject them in accordance with the conditions herein stipulated.
            </p>
            <p>
              7. The Client accepts, confirms and declares that that T&amp;C are
              proof of true will to seek representation on their behalf by
              Airjust and that this should be taken to constitute such proof by
              flight operating carriers.
            </p>
            <p>
              8. The Client grants Airjust the right of collecting the
              compensation from the flight operating carrier, thereupon to
              distribute it from Airjust’s business accounts in accordance with
              the pricing policy stipulated within the conditions.
            </p>
            <p></p>
            <p>
              <b>Section 3: Description of Claims Management Process</b>
            </p>
            <p></p>
            <p>
              1. Flight data required for the successful pursuit of the claim
              may be submitted by phone, mobile application, email, or through
              the online form located on the Airjust website. Receipt of such
              information is inclusive of the acceptance document/representation
              document requisite for the delegation of litigation to a third
              party. Upon receipt of the information, Airjust will undertake an
              assessment of the claim’s merits. Subject to approval, Airjust
              will undertake all requisite actions in relation to the claims
              managemet process including collection of flight compensation.
            </p>
            <p>
              2. If a claim is rejected by the airline, or if no response is
              received after a reasonable period, Airjust may, on its own
              discretion, undertake such legal proceedings as it deems relevant
              to the claims management process.
            </p>
            <p>
              3. For the purposes of the above mentioned legal proceedings, the
              Customer/Client will permit Airjust to provide Airjust’s legal
              representative access to all of the data provided to Airjust and
              allow the legal representative to transfer information concerning
              the proceedings to Airjust. If it is necessary to do so the client
              will sign a respective agreement with the legal representative
              although the authorisation document and representation document
              will be taken as being sufficient for the purposes of conferring
              the Customer/Client’s consent to representation. Settlement
              between Airjust its legal representative is taken to be a reserved
              matter for Airjust and its legal representative.
            </p>
            <p>
              4. If, following an assessmet of the claim, Airjust’s legal
              representative comes to the conclusion that the claim is unlikely
              to succeed, the Customer/Client will be so advised
              <span class="Apple-converted-space">&nbsp; </span>and Airjust will
              reserve the right not to pursue the claim any further. If, after
              further analysis, the an alternative assessment of the chances of
              success is reached, Airjust reserves the right to resume all
              relevant claims management proceedings.
            </p>
            <p>
              5. In the event of the initiation of legal proceedings, Airjust
              will cover any costs incurred in the event the lawsuit is lost. If
              a successful outcome is reached or a settlement has been reached
              between the flight operator/carrier and Airjust, Airjust will
              cover any costs incurred that are not covered by the flight
              operator/carrier. If a lawsuit is won, a claim for reimbursement
              of court fees will be put forward on behalf of the
              Customer/Client, this provision extend to the reimbursement of any
              legal costs incurred from attorney fees or Airjust’s chosen legal
              representative.
            </p>
            <p>
              6. The Customer/Client acknowledges that it is the sole decision
              of Airjust to accept any settlement offer consequent to the
              assignation, through the representation/authority documents which
              is accepted as being sufficient legal ground for this discretion
              to be effective.
            </p>
            <p>
              The customer accepts that all costs incurred by Airjust and
              claimed from the flight operating carrier are paid to Airjust.
            </p>
            <p></p>
            <p>
              <b>Section 4: Payment and fees structuring</b>
            </p>
            <p></p>
            <p>
              1. Airjust provides Justice as a Service free of charge unless
              successful in collecting Flight Compensation (“no win no fee”).
            </p>
            <p>
              Subject to a successful claim, and received flight compensation,
              Airjust will pay the Customer/Client the agreed amount as
              stipulated in the terms and conditions, and further specified in
              the pricelist.
            </p>
            <p>
              The client understands that, provided Airjust assesses that the
              claims management process may be more effectively pursued through
              legal proceedings, it is entitled to commence such proceedings,
              which may result in an increase in the proportion of the
              compensation payout due to Airjust, as specified in the pricelist.
            </p>
            <p>
              Any costs incurred by Airjust on the basis of incorrect,
              incomplete or fraudulent data will incur a penalty for which the
              client will be liable.
            </p>
            <p>
              2. If the Customer/Client has provided wrong, inaccurate, or
              otherwise inadequate information to pay the Flight Compensation,
              and payment completion is impossible (such as through failed bank
              transfer), Airjust will take all reasonable steps to contact/reach
              the Customer/Client, through the means of communication the client
              has provided.
            </p>
            <p>
              3. In the event that Customer/Client does not respond to provide
              correct payment information, Airjust will be deemed to be entitled
              to keep the part of the Flight Compensation that should in
              ordinary circumstances have been transferred to the
              Customer/Client.
            </p>
            <p>
              4. Upon payment of the Customer/Client’s monies Airjust shall not
              be liable for the effect of any lost credit cards, pay cheques or
              any losses resulting from the provision of incorrect information
              including but not limited to bank accounts.
            </p>
            <p>
              5. In the event of payment into an incorrect bank account, Airjust
              will not be held liable for the recovery of these funds. Liability
              will rest with the Customer/Client.
            </p>
            <p>
              6. Airjust shall not be liable for any inability/failure to pay
              the stipulated compensation due to circumstances beyond its
              reasonable control, including, but not limited to strike,
              lock-out, labour dispute, act of God, war, riot, compliance with a
              law or government order, rule, regulation or the like.
            </p>
            <p>
              7. Airjust will provide an electronic invoice via email for any
              services delivered and fees charged, upon the client’s request.
            </p>
            <p></p>
            <p>
              <b>s.5: Data Policy</b>
            </p>
            <p></p>
            <p>
              1. Data provided to Airjust will be used solely for the purposes
              of pursuing the legal claim compensation. All information
              pertaining to Airjust’s data collection, storage and usage policy
              can be located in Airjust’s privacy policy.
            </p>
            <p>
              i. The Customer/Client provides Airjust with personal data under
              the General Data Protection Regulation or other data protection
              laws that may apply with the knowledge and permission on the part
              of the Customer/Client and, where applicable, given on behalf of
              other passengers or the group with which the individual is
              travelling and has claimed for. The Customer/Client understands
              that explicit consent is granted on behalf of the group for which
              they claim and is to be used only for the purposes laid out within
              the agreement. Airjust will only transfer the personal data to
              third parties under the following conditions.
            </p>
            <p>ii. if the Customer/Client has explicitly consented;</p>
            <p>
              iii. if it is directly in relation to the reason for which it was
              initially provided.
            </p>
            <p>
              iv. if it is necessary for all steps pertaining to completion of
              the Agreement with the Customer/Client;
            </p>
            <p>
              v. if it is required due to a legal obligation, administrative, or
              court order;
            </p>
            <p>
              vi. if it is required for conducting legal claims or in
              proceedings in court.
            </p>
            <p>
              vii. It is necessary for the purposes of data security- prevention
              of improper usage, criminal activity, unlawful actions.
            </p>
            <p></p>
            <p>Confirmation statement:</p>
            <p></p>
            <p>
              1. The Customer/Client confirms that the information is true and
              accurate, up to date, and in cases where fellow passengers may be
              involved it is provided with consent on their behalf.
            </p>
            <p>
              2. The Customer/Client agrees to fully indemnify Airjust from any
              losses arising as a result of incomplete information, fraudulent
              information, inaccurate information and any third party actions
              arising in respect of the aforementioned factors.
            </p>
            <p></p>
            <p>
              3. In the case of the provision of incorrect/incomplete/fraudulent
              information Airjust may, at its discretion, terminate the claim.
              Termination in this respect will not give grounds to any right of
              compensation for the Customer/Client, the Customer/Client is aware
              of this, and consents to this provision.
            </p>
            <p></p>
            <p>
              <b>§8 Right of withdrawal</b>
            </p>
            <p>
              1.Pursuant to provisions of European Consumer Legislation the
              Customer/Client can withdraw their acceptance of the agreement
              within 14 days from entry into the Agreement. This may be done by
              a clear statement of a wish to withdraw from the agreement
              communicated by email to : info@airjust.com. As a consequence of
              the nature of the service provided by Airjust, the Customer/Client
              cannot withdraw from the agreement once Airjust has informed them
              that their claim has been accepted. This is a result of acceptance
              signifying the completion of the service they have requested.
            </p>
            <p></p>
            <p></p>
            <p>
              <b>Price List</b>
            </p>
            <p></p>
            <p>Definitions:</p>
            <p>
              All definitions pertaining to price list may be found in the terms
              and conditions.
            </p>
            <p>Service Fee:</p>
            <p>
              On the successful provision of Justice as a Service (meaning the
              receipt by the client of Flight Delay/Cancellation Compensation),
              AirJust will deduct its fee from the compensation.
            </p>
            <p>
              The service fee will amount 30%, including VAT of any compensation
              paid out by airlines pursuant to the claim.
            </p>
            <p>Additional Fee (Legal Action):</p>
            <p>
              This fee will only be charged on the condition that the successful
              pursuit of a claim necessitated legal action, it will be deducted
              from flight compensation on top of the Service Fee.
            </p>
            <p>
              The Fee amounts to 15% of the Flight Compensation including VAT.
            </p>
            <p>Foreign Exchange Clarification:</p>
            <p>
              AirJust commits to the provision of the correct amount of
              compensation for any claims filed pursuant to EC261, in EUR or
              British Pound (if paid within UK jurisdiction). This is the case
              notwithstanding that AirJust may be paid in alternative
              currencies, and consequently incur costs relating to currency
              conversion. We will never deduct any such additional costs from
              the compensation you are owed.
            </p>
            <p>Bank Transfers</p>
            <p>
              AirJust will not be liable for any additional fees/costs related
              to bank accounts of third-party intermediaries or the banks of the
              customer/client.
            </p>
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="gray" mr={3} onClick={onClose}>
              Close
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
      <Formiz connect={form} onValidSubmit={handleSubmit}>
        <form noValidate onSubmit={handleSubmitStep}>
          {/* Need to have the page header on one side of the page and the index of the step on the other */}
          <Flex justifyContent="space-between">
            <PageHeader>Submit Claim</PageHeader>
            <Spacer />
            <div pt={5} style={{ textAlign: 'right' }}>
              <b>
                Step {form.currentStep?.index + 1} of {form.steps.length}
              </b>
            </div>
          </Flex>

          <FormizStep name="step1">
            <Alert
              status="info"
              color="blue.900"
              p={4}
              mb={5}
              mt={3}
              borderRadius={5}
              fontWeight="bold"
            >
              <CheckCircleIcon marginRight={1} color="blue.900" />
              <p color="blue.900">
                Congratulations! Your flight is eligible, please cofirm the
                details below.
              </p>
            </Alert>

            <Grid
              templateColumns={{
                lg: 'repeat(2, 1fr)',
                md: 'repeat(2, 1fr)',
                sm: 'repeat(1, 1fr)',
              }}
              gap={{ lg: 6, md: 6, sm: 0 }}
            >
              <FieldInput
                name="flightDate"
                label="Flight Date"
                required="Required"
                type="date"
                defaultValue={flightDate}
                onChange={e => setFlightDate(e)}
              />
              {/* <FieldSelectAirline
                  name="airline"
                  label="Airline"
                  required="Required"
                  defaultValue={flightAirline}
                  onChange={(e) => setFlightAirline(e)}
                /> */}
              {/* <FieldInput
                  name="flightNumber"
                  label="Flight Number"
                  required="Required"
                  defaultValue={flightNumber}
                  onChange={(e) => setFlightNumber(e)}
                  type="number"
                /> */}
              <FieldInput
                name="flightIata"
                label="Flight Number"
                required="Required"
                defaultValue={flightIata}
                onChange={e => setFlightIata(e)}
              />
            </Grid>

            <Grid
              templateColumns={{
                lg: 'repeat(2, 1fr)',
                md: 'repeat(2, 1fr)',
                sm: 'repeat(1, 1fr)',
              }}
              gap={{ lg: 6, md: 6, sm: 0 }}
            >
              {/* <Checkbox
                  mb={6}
                  isChecked={isFlightCancelled}
                  onChange={() => setIsFlightCancelled((x) => !x)}
                >
                Flight Cancelled
              </Checkbox> */}
              {isFlightCancelled && (
                <>
                  <FieldSelect
                    name="newArrivalDelay"
                    label="If rescheduled, how much longer did it take to reach your destination?"
                    required="Required"
                    defaultValue={newArrivalDelay}
                    onChange={e => setNewArrivalDelay(e)}
                    options={[
                      { value: 'Under 2 hours', label: 'Under 2 hours' },
                      { value: '2 - 3 hours', label: '2 - 3 hours' },
                      { value: '3 - 4 hours', label: '3 - 4 hours' },
                      { value: 'Over 4 hours', label: 'Over 4 hours' },
                      { value: 'Never arrived', label: 'Never arrived' },
                    ]}
                  />
                  <FieldSelect
                    name="advanceNotice"
                    label="How much notice did you receive of the cancellation?"
                    required="Required"
                    defaultValue={advanceNotice}
                    onChange={e => setAdvanceNotice(e)}
                    options={[
                      { value: 'Less than 7 Days', label: 'Less than 7 Days' },
                      { value: '7 - 13 Days', label: '7 - 13 Days' },
                      { value: '14 Days or more', label: '14 Days or more' },
                    ]}
                  />
                </>
              )}
              {!isFlightCancelled && (
                <FieldInput
                  name="flightDelay"
                  label="Delay (in hours e.g. 3.5)"
                  defaultValue={Math.round((flightDelay / 60) * 10) / 10}
                  onChange={e => setFlightDelay(e * 60)}
                  validations={[
                    {
                      rule: isMinNumber(3),
                      message:
                        'Your flight must be delayed by at least 3 hours cancelled to be eligible for compensation',
                    },
                  ]}
                  type="number"
                />
              )}
            </Grid>
            <Divider />
            <Flex paddingBottom={5} paddingTop={5}>
              <Box>Was your flight cancelled?</Box>
              <Spacer />
              <RadioGroup
                onChange={() => setIsFlightCancelled(x => !x)}
                value={isFlightCancelled}
              >
                <Stack direction="row">
                  <Radio value={true}>Yes</Radio>
                  <Spacer />
                  <Radio value={false}>No</Radio>
                </Stack>
              </RadioGroup>
            </Flex>
            <Divider />
          </FormizStep>
          <FormizStep name="step2">
            <Alert
              status="info"
              color="blue.900"
              p={4}
              mb={5}
              mt={3}
              borderRadius={5}
              fontWeight="bold"
            >
              <CheckCircleIcon marginRight={1} color="blue.900" />
              <p color="blue.900">
                Almost done! This is information is stored securely and only
                used to confirm your compenstation with the airline
              </p>
            </Alert>
            <Grid
              templateColumns={{ lg: '48% 48%', md: '48% 48%', base: '200' }}
              gap={{ lg: 5, md: 5, base: 0 }}
            >
              <FieldInput
                name="address"
                label="Address"
                required="Required"
                formatValue={val => (val || '').trim()}
              />
              <FieldInput
                name="city"
                label="City"
                required="Required"
                formatValue={val => (val || '').trim()}
              />
              <FieldInput
                name="zipcode"
                label="Post Code"
                required="Required"
                formatValue={val => (val || '').trim()}
              />
              <FieldInput
                name="country"
                label="Country"
                required="Required"
                formatValue={val => (val || '').trim()}
              />
            </Grid>
          </FormizStep>
          <FormizStep name="step3">
            <Alert
              status="info"
              color="blue.900"
              p={4}
              mb={5}
              mt={3}
              borderRadius={5}
              fontWeight="bold"
            >
              <CheckCircleIcon marginRight={1} color="blue.900" />
              {/* Change text based on size */}
              <p color="blue.900">Last step!</p>
            </Alert>
            <Center w="100%" pt={3} pb={3}>
              <Grid
                gridTemplateColumns={{
                  xl: '28rem 15rem',
                  lg: '20rem 15rem',
                  md: '20rem 15rem',
                  base: '100%',
                }}
                gap={7}
              >
                <Image
                  border="1px"
                  borderRadius="12px"
                  borderColor="gray.200"
                  src={airjustTicket}
                  alt="Formiz"
                  w={{ base: '17rem', lg: '25rem' }}
                />
                <Box w="100%" pr={4} pl={4}>
                  Booking reference number (also called PNR Code and Record
                  Locator) can be found on your ticket or email confirmation. It
                  is shown here as "Record Locator"
                </Box>
              </Grid>
            </Center>
            <FieldInput
              name="bookingRef"
              label="Booking Reference Number"
              required="Required"
            />
            <FieldInput
              name="check"
              label="I agree to the terms and conditions"
              required="Required"
              type="checkbox"
            />

            {/* Make this an accordian */}
            {/* auto expand the first item */}
            <Accordion allowToggle w="100%" defaultIndex={[0]}>
              <AccordionItem>
                <h2>
                  <AccordionButton style={{ '-webkit-padding-start': 0 }}>
                    <Box as="span" flex="1" textAlign="left">
                      Payout Information
                    </Box>
                    <AccordionIcon />
                  </AccordionButton>
                </h2>
                <AccordionPanel pb={4}>
                  <Box color="black" w="100%" pt={2} pb={5} fontSize={14}>
                    <Grid gridTemplateColumns={{ xl: '50rem 5rem' }} gap={7}>
                      At Airjust, we talk to the airline for you, working hard
                      to get your money back and send it straight to you,
                      without charging you anything to start. If we win, we take
                      a part of the money (15%-40%) to keep our service going,
                      so we can help others like you - Ready to find out what
                      your payout could be? Just submit your claim and let us do
                      the work, stress-free.
                    </Grid>
                  </Box>
                </AccordionPanel>
              </AccordionItem>
              <AccordionItem>
                <h2>
                  <AccordionButton style={{ '-webkit-padding-start': 0 }}>
                    <Box as="span" flex="1" textAlign="left">
                      Terms & Conditions
                    </Box>
                    <AccordionIcon />
                  </AccordionButton>
                </h2>
                <AccordionPanel pb={4}>
                  <Box
                    w="100%"
                    pt={5}
                    pb={5}
                    fontWeight="bold"
                    onClick={onOpen}
                    color="brand.500"
                    _hover={{
                      cursor: 'pointer',
                      textDecoration: 'underline',
                    }}
                  >
                    Terms & Conditions
                  </Box>
                  <Box color="black" w="100%" pt={2} pb={5} fontSize={14}>
                    By submitting you agree to for us to file a compensation
                    claim on your behalf and send payment to you.
                  </Box>
                </AccordionPanel>
              </AccordionItem>
            </Accordion>
            <br />
          </FormizStep>
          {/* <FormizStep name="step4">
              <Box w='100%' pt={5} pb={5}>
                <p><b>Please upload your boarding pass or email confirmation from the airline</b></p>
                <p>This allows us to verify your identity and increases your chances of getting paid</p>
              </Box>
              <FieldUpload
                required="Required"
                w="100%"
                name="ticketImage"
              />
            </FormizStep> */}
          {/* <FormizStep name="step5">
              <Box w='100%' pt={5} pb={5}>
                <p><b>Please sign below so we can legally represent your claim</b></p>
                <p>By signing you agree to legal repesentation by Airjust and our solicitors and for us to file a compensation claim on your behalf.</p>
                {'\u00A0'}
                <p>By signing you are also agreeing to our Terms and Conditions which can be found <a onClick={onOpen} rel="noopener noreferrer"><b>here</b></a>.</p>
              </Box>
              <Center mt={3} mb={3} w='100%' border='1px' borderRadius="12px" borderColor="gray.200">
                <SignatureCanvas ref={(ref) => { setSig(ref) }} penColor='black'
                  canvasProps={{ width: 630, height: 300, className: 'sigCanvas' }} />
              </Center>
              <Button w="100%" variant='ghost' onClick={() => sig.clear()}>
                Clear
              </Button>
            </FormizStep> */}
          <StepperWrapper mt="5" isloading={isLoading}>
            {/* <DotsStepper /> */}
          </StepperWrapper>
        </form>
      </Formiz>
    </Box>
  );
};

const StepperWrapper = ({ title, children, isloading, ...rest }) => (
  <NextButton isLoading={isloading} />
);

const DotsStepper = props => {
  const form = useForm({ subscribe: 'form' });
  const spacing = 4;

  return (
    <Stack
      direction="row"
      display="flex"
      alignItems="center"
      justifyContent="center"
      spacing={{ lg: 5, md: 5, base: 3 }}
      {...props}
    >
      {form.steps?.map(step => {
        const inactiveProps = !step.isVisited
          ? {
              bg: 'gray.100',
              color: 'gray.400',
            }
          : {};

        const visitedProps =
          step.isVisited && !step.isCurrent
            ? {
                bg: 'white',
                color: 'brand.500',
                borderColor: 'currentColor',
                as: 'button',
                type: 'button',
                onClick: () => form.goToStep(step.name),
                _hover: {
                  bg: 'brand.500',
                  color: 'white',
                  borderColor: 'brand.500',
                },
                _focus: {
                  boxShadow: 'outline',
                },
              }
            : {};

        const currentProps = step.isCurrent
          ? {
              zIndex: 1,
              bg: 'brand.500',
              color: 'white',
            }
          : {};

        return (
          <AspectRatio key={step.name} w="6" ratio={1}>
            <Box
              zIndex={0}
              borderRadius="full"
              border="2px solid transparent"
              fontWeight={step.isCurrent || step.isVisited ? 'bold' : null}
              outline="none"
              fontSize="xs"
              overflow="visible"
              transition="0.2s"
              _after={
                step.index !== 0
                  ? {
                      content: '""',
                      display: 'block',
                      position: 'absolute',
                      mt: '-1px',
                      mr: '2px',
                      top: '50%',
                      right: '100%',
                      bg:
                        step.isVisited || step.isCurrent
                          ? 'brand.500'
                          : 'gray.100',
                      h: '2px',
                      w: spacing,
                    }
                  : null
              }
              {...inactiveProps}
              {...visitedProps}
              {...currentProps}
            >
              {step.index + 1}
            </Box>
          </AspectRatio>
        );
      })}
    </Stack>
  );
};

const PreviousButton = props => {
  const form = useForm({ subscribe: 'form' });

  if (form.isFirstStep) {
    return null;
  }

  return (
    <Button
      size="md"
      w={{ lg: '60%', md: '60%', sm: '100%' }}
      color="black"
      onClick={form.prevStep}
      variant="ghost"
      {...props}
    >
      Previous
    </Button>
  );
};

const NextButton = props => {
  const form = useForm({ subscribe: 'form' });
  return (
    <Button
      type="submit"
      size="md"
      w="100%"
      colorScheme="brand"
      isDisabled={
        (form.isLastStep ? !form.isValid : !form.isStepValid) &&
        form.isStepSubmitted
      }
      {...props}
    >
      {form.isLastStep ? 'Submit' : 'Next'}
    </Button>
  );
};
