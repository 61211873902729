import React, { useState, useEffect } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { Submit} from './pages/Submit';
import { Verify } from './pages/Verify';
import { SubmitTicket } from './pages/SubmitTicket';
import {Box,
} from '@chakra-ui/react'
import { Flex, ChakraProvider, CSSReset, HStack } from '@chakra-ui/react';
import { NavBar } from './layout/NavBar-New';
import useUETTracking from './useUETTracking';

import theme from './theme';
import { useLocation } from 'react-router-dom';

const RouterWrapper = () => {

  return (
    <Routes>
      <Route path="/" element={<Verify />} />
      <Route path="/submit" element={<Submit />} />
      <Route path="/submit-additional-info" element={<SubmitTicket />} />
    </Routes>
  );
};


function App() {
  
  return (
    <BrowserRouter>
      <ChakraProvider theme={theme}>
        <CSSReset />
        <Flex
          minH="100vh"
          m="auto"
          overflow="hidden"
          flexDirection={{ base: 'column', lg: 'row' }}
        >
          <NavBar />
          {/* route should be centered in the page hoizontally and vertically */}
          <Flex
          flex={1}
            flexDirection="row"
            justifyContent="center"
            alignItems="center"
            fontFamily="Poppins, sans-serif"
            padding={{ base: '0 1rem', lg: '0 2rem' }}
    >
      <Box
        w={{ base: '100%', lg: '80%' }}
        maxW="1000px"
        mx="auto"
        my="auto"
      >
              <RouterWrapper />
            </Box>
          </Flex>
        </Flex>
      </ChakraProvider>
    </BrowserRouter>
  );
}


export default App;
