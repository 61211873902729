
import React, { useState, useEffect } from 'react';
import { BrowserRouter, Route, Routes, useNavigate } from 'react-router-dom';
import { Formiz, useForm } from '@formiz/core';
import ReCAPTCHA from "react-google-recaptcha";
import BingTracking from 'react-tracking-bing';
import { CheckCircleIcon} from '@chakra-ui/icons'
import { useLocation } from 'react-router-dom';
import {
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
} from '@chakra-ui/react'
import { isEmail } from '@formiz/validations';
import {
  Drawer,
  DrawerBody,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton, useToast, Box
} from '@chakra-ui/react'
import { Heading, SimpleGrid, useDisclosure, Grid, Button, Flex, ChakraProvider, CSSReset, HStack } from '@chakra-ui/react';
import { FieldInput } from '../components/Fields/FieldInput';
import { FieldSelectAirline } from '../components/Fields/FieldSelectAirline';
import { PageLayout } from '../layout/PageLayout';
import { PageHeader } from '../components/PageHeader';
import useUETTracking from '../useUETTracking';
import ReactPixel from 'react-facebook-pixel';

function getCookie(name) {
  var nameEQ = name + "=";
  var ca = document.cookie.split(';');
  for(var i=0;i < ca.length;i++) {
      var c = ca[i];
      while (c.charAt(0)===' ') c = c.substring(1,c.length);
      if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length,c.length);
  }
  return null;
}


async function fetchData(values) {
  const formURL = 'https://0apyc838t2.execute-api.eu-west-2.amazonaws.com/prod/validateclaim';
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json', 'x-api-key': 'EhBGDT2nBT96tqyFMtc8u4YOGQee5gUM7DOTomgE' },
    body: JSON.stringify(values)
  };

  const response = await fetch(formURL, requestOptions);
  const data = await response.json();

  return data;
}

export const Verify = () => {
  useUETTracking([]);
  const options = {
    autoConfig: true,
    debug: false,
  };
  //Test Pixel
  ReactPixel.init('1300921143958590', null, options);
  ReactPixel.pageView();
  const form = useForm({ subscribe: 'form' });
  const { isOpen, onOpen, onClose } = useDisclosure()
  const [captchaPassed, setCaptchPassed ] = useState(false);
  const [initalInfo, setInitalInfo] = useState({});
  const [status, setStatus] = useState('idle');
  const isLoading = status === 'loading' || form.isValidating;
  const toast = useToast()

  var referralCode = "none";
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  var referralCodeQuery = searchParams.get('referral');
  if (referralCodeQuery !== null) {
    document.cookie = "airjust-referral=" + referralCodeQuery + "; domain=.airjust.co; path=/";
    referralCode = referralCodeQuery;
  }else {
    var referralCodeCookie = getCookie('airjust-referral');
    if (referralCodeCookie !== null) {
      referralCode = referralCodeCookie;
    }
  }


  function onChangeCaptcha(value) {
    setCaptchPassed(true)
  }
  useEffect(() => {
    BingTracking.trackEvent("verifyView","","verifyView","0");
    window.gtag('config', 'G-83KZEHBVC0');
    window.gtag('config', 'UA-224099637-1');
    let airjustCookiesSeen = localStorage.getItem('airjust-cookies-seen');
    if (airjustCookiesSeen !== "true") {
      onOpen()
      localStorage.setItem('airjust-cookies-seen', "true")
    }
  })

  let navigate = useNavigate();

  const handleSubmit = async (values) => {
    setStatus('loading');
    if (captchaPassed) {
      const data = await fetchData(values);
      console.log(data)
      await setInitalInfo(data);
      setTimeout(function () {

      }, 2000);


      if (data.statusCode === 500) {
        console.log("fail")
        toast({
          title: data.body,
          description: "We only can accept claims that include a 3+ hour delay, cancellation or other extreme circumstances. If you still believe you are eligible for compensation please email us at help@airjust.co",
          status: 'error',
          duration: 10000,
          isClosable: true,
        })
        setStatus('fail');
      } else {
        localStorage.setItem('airjust-person', JSON.stringify(JSON.parse(data.body).person));
        localStorage.setItem('airjust-flight', JSON.stringify(JSON.parse(data.body).flight));
        localStorage.setItem('airjust-referral', referralCode);
        toast({
          title: "Success",
          description: "We found that you are eligible",
          status: 'success',
          duration: 10000,
          isClosable: true,
        })
        navigate("./submit", { replace: true });
        setStatus('success');
      }
    }
    else {
      toast({
        title: "Captcha Failed",
        status: 'error',
        duration: 10000,
        isClosable: true,
      })
      setStatus('fail');
    }
  };

  return (
    // Center content horizontally and vertically
      <Box>
      <Drawer placement='bottom' onClose={onClose} isOpen={isOpen}>
        <DrawerOverlay />
        <DrawerContent>
          <DrawerBody textAlign={"center"}>
            <p>We use cookies to ensure that we give you the best experience on our website. If you continue to use this site we will assume that you agree to their use.</p>
            <Button mt={2} onClick={onClose} >Agree</Button>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
      
      <Formiz connect={form} onValidSubmit={handleSubmit} autoForm>
        
      
          <PageHeader></PageHeader>
          <Alert status='info' color="blue.900">
    <CheckCircleIcon marginRight={1} color="blue.900" />
    <p color="blue.900">
    Check your eligibility, <b> it's free!</b>
    </p>
  </Alert>
          <Grid templateColumns={{lg: "repeat(3, 1fr)", base: "repeat(1, 1fr)"}}
           gap={{ lg: 7, md: 7, base: 0 }} mt={4}>
            <FieldInput
              name="flightDate"
              label="Flight Date"
              required="Required"
              type="date"
              formatValue={(val) => (val || '').trim()}
            />
            <FieldSelectAirline
              name="airline"
              label="Airline"
              placeholder='e.g. British Airways'
              required="Required"
              formatValue={(val) => (val || '').trim()}
            />
            <FieldInput
              name="flightNumber"
              label="Flight Number"
              placeholder='e.g. 542'
              required="Required"
              formatValue={(val) => (val || '').trim()}
            />
          </Grid>
          <Grid templateColumns='repeat(2, 1fr)' gap={6} mt={0}>
            <FieldInput
              name="firstName"
              label="First Name"
              required="Required"
              formatValue={(val) => (val || '').trim()}
            />
            <FieldInput
              name="lastName"
              label="Last Name"
              required="Required"
              formatValue={(val) => (val || '').trim()}
            />
          </Grid>
          <FieldInput
            name="email"
            label="Email"
            type="email"
            formatValue={(val) => (val || '').trim()}
            required="Required"
            validations={[
              {
                rule: isEmail(),
                message: 'Not a valid email',
              },
            ]}
          >
          </FieldInput>
          

          <Flex direction={{ lg: "row", md: "row", base: "column" }}>
            <Box>
              <ReCAPTCHA
                sitekey="6LcIWtcgAAAAANPIR0r1g8XC1eI_TuafqfubVNJ5"
                onChange={onChangeCaptcha}
              />
            </Box>
            <Button
              isLoading={isLoading}
              w={{ lg: 'auto', md: 'auto', base: '100%' }}
              mt="5"
              type="submit"
              ml="auto"
              backgroundColor={isLoading ? '#4E6FE3' : '#4E6FE3'}
              color="white"
              isDisabled={
                (!form.isValid || form.isValidating) && form.isSubmitted
              }
            >
              Check Compensation
            </Button>
          </Flex>
      </Formiz>
      </Box>
  );
}