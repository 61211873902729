import React from "react";
import { Box, HStack, Text } from "@chakra-ui/react";

export const NumberedSteps = ({ step, text, isActive }) => {
  return (
    <Box w="100%" position="relative">
      {isActive && (
        <Box
          position="absolute"
          right={0}
          top={0}
          bottom={0}
          width="3px"
          bg="#131733"
        />
      )}
      <HStack
        px={2}
        py={4}
        borderRadius="md"
        w="100%"
        bgColor={isActive ? "#bee3f8" : "transparent"}
      >
        <Box
          borderRadius="50%"
          border="1px solid"
          borderColor={isActive ? "#131733" : "gray.500"}
          width="24px"
          height="24px"
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <Text
            fontWeight="bold"
            color={isActive ? "#131733" : "gray.500"}
          >
            {step}
          </Text>
        </Box>
        <Text
          fontWeight={isActive ? "bold" : "normal"}
          color={isActive ? "#131733" : "gray.500"}
        >
          {text}
        </Text>
      </HStack>
    </Box>
  );
};
