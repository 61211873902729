import React, { useEffect, useState } from 'react';
import { useField } from '@formiz/core';
import { FormGroup } from '../FormGroup';
import {
  Select
} from "chakra-react-select";

export const FieldSelect = (props) => {
  
  const {
    errorMessage,
    id,
    isValid,
    isSubmitted,
    resetKey,
    setValue,
    value,
    otherProps,
  } = useField(props);
  const { required, name } = props;
  const {
    children, label, options = [], placeholder, helper, ...rest
  } = otherProps;
  const [isTouched, setIsTouched] = useState(false);
  const showError = !isValid && (isTouched || isSubmitted);

  useEffect(() => {
    setIsTouched(false);
  }, [resetKey]);

  const formGroupProps = {
    errorMessage,
    helper,
    id,
    isRequired: !!required,
    label,
    showError,
    name,
    ...rest,
  };


  return (
    <FormGroup {...formGroupProps}>
      <Select
        id={id}
        //value={value || ''}
        onBlur={() => setIsTouched(true)}
        aria-invalid={showError}
        aria-describedby={!isValid ? `${id}-error` : undefined}
        placeholder={placeholder}
        onChange={(e) => setValue(e.value)}
        options = {options}
      >
      </Select >
      {children}
    </FormGroup >
  );
};
