import { extendTheme } from '@chakra-ui/react';

export default extendTheme({
  colors: {
    brand: {
      50: '#edfdf4',
      100: '#e2fbed',
      200: '#bee3f8',
      300: '#bee3f8',
      400: '#4E6FE3',
      500: "#4E6FE3",
      600: '#4E6FE3',
      700: '#4E6FE3',
      800: '#4E6FE3',
      900: '#4E6FE3',
      1000: '#4E6FE3',
    },
  },
});
