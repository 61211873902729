import React, { useEffect, useState } from "react";
import {
  Box,
  Flex,
  Heading,
  Text,
  VStack,
  useBreakpointValue,
  Center,
  Image,
} from "@chakra-ui/react";
import { NumberedSteps } from "./NumberedSteps";
import { useLocation } from 'react-router-dom';
import logo from './logo.svg';

export const NavBar = () => {
  const flexDirection = useBreakpointValue({ base: "column", lg: "row" });
  const paddingY = useBreakpointValue({ base: 2, md: 5 });
  const stepperDisplay = useBreakpointValue({ base: "none", sm: "none", md: "none", lg: "flex" }); // "flex
  const [activeStep, setActiveStep] = useState(1);

  //  the active step is changed by what url is being used
  const location = useLocation();
  useEffect(() => {
    if (location.pathname === "/") {
      setActiveStep(1);
    } else if (location.pathname === "/submit") {
      setActiveStep(2);
    } else if (location.pathname === "/submit-additional-info") {
      setActiveStep(3);
    }
  }, [location]);
  return (
    <Flex
      as="nav"
      flexDirection={flexDirection}
      justifyContent="space-between"
      alignItems="center"
      py={paddingY}
      px={5}
      backgroundColor="#FAFAFA"
      boxShadow="md"
      fontFamily="Poppins, sans-serif"
      maxWidth={{ base: "100%", md: "100%", lg: "20%" }}
    >
      <Box>
        <Center>
          <Box w="100%" align={{ base: "left", lg: "center" }}>
            <Image src={logo} alt="Airjust" width={{ base: "30%", lg: "60%" }} pb="10px" />
          </Box>
        </Center>
        <Box textAlign={{ base: "center", lg: "left" }}>
        {/* <Text mt={2} fontSize="xl" fontWeight="medium">
          Flight Delayed or Cancelled?
        </Text> */}
        <Text mt={2} fontSize="md" fontWeight="normal" display={{ base: "none", lg: "block" }} >
          We can help you get up to £540 in compensation from the airline
        </Text>
        </Box>
        <VStack
          alignItems="flex-start"
          spacing={4}
          mt={5}
          fontSize="md"
          position="relative"
          w="100%"
          display={stepperDisplay}
        >
          <Box
            position="absolute"
            top="-8px"
            left="0"
            right="0"
            height="1px"
            bg="gray.200"
          />
          <NumberedSteps
            step={1}
            text="Check Flight"
            isActive={activeStep === 1}
          />
          <NumberedSteps
            step={2}
            text="Confirm Information"
            isActive={activeStep === 2}
          />
          <NumberedSteps
            step={3}
            text="Get Compensated"
            isActive={activeStep === 3}
          />
          <Box
            position="absolute"
            bottom="-8px"
            left="0"
            right="0"
            height="1px"
            bg="gray.200"
            display={stepperDisplay}
          />
        </VStack>
      </Box>
    </Flex>
  );
};
