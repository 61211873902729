import React, { useEffect, useState } from 'react';
import { useField } from '@formiz/core';
import { FormGroup } from '../FormGroup';
import {
  Select
} from "chakra-react-select";

export const FieldSelectAirline = (props) => {
  
  const {
    errorMessage,
    id,
    isValid,
    isSubmitted,
    resetKey,
    setValue,
    value,
    otherProps,
  } = useField(props);
  const { required, name } = props;
  const {
    children, label, options = [], placeholder, helper, ...rest
  } = otherProps;
  const [isTouched, setIsTouched] = useState(false);
  const showError = !isValid && (isTouched || isSubmitted);

  useEffect(() => {
    setIsTouched(false);
  }, [resetKey]);

  const formGroupProps = {
    errorMessage,
    helper,
    id,
    isRequired: !!required,
    label,
    showError,
    name,
    ...rest,
  };

  const jsonAirlines = {
    "40-Mile Air": "Q5",
    "Abacus International": "1B",
    "ABX Air": "GB",
    "Adam Air": "KI",
    "Adria Airways": "JP",
    "Aegean Airlines": "A3",
    "Aer Lingus": "EI",
    "Aero Airlines": "EE",
    "Aero Asia International": "E4",
    "Aero California": "JR",
    "Aero Condor Peru": "Q6",
    "Aero Contractors": "AJ",
    "Aero Flight": "GV",
    "Aero Services Executive": "W4",
    "Aero VIP": "2D",
    "Aero-Charter Ukraine": "DW",
    "Aerocondor": "2B",
    "Aeroflot Russian Airlines": "SU",
    "Aerogal": "2K",
    "Aerolane": "XL",
    "Aerolíneas Argentinas": "AR",
    "Aeromar": "VW",
    "Aeromist-Kharkiv": "HT",
    "Aeroméxico": "AM",
    "Aeropelican Air Services": "OT",
    "Aeropostal Alas de Venezuela": "VH",
    "AeroRepública": "P5",
    "AeroSur": "5L",
    "Aerosvit Airlines": "VV",
    "Afrinat International Airlines": "Q9",
    "Afriqiyah Airways": "8U",
    "Aigle Azur": "ZI",
    "Air Algérie": "AH",
    "Air Alpha Greenland": "GD",
    "Air Arabia Maroc": "3O",
    "Air Arabia": "G9",
    "Air Armenia": "QN",
    "Air Astana": "KC",
    "Air Austral": "UU",
    "Air Baltic": "BT",
    "Air Bangladesh": "B9",
    "Air Berlin": "AB",
    "Air Botswana": "BP",
    "Air Bridge Carriers": "AK",
    "Air Burkina": "2J",
    "Air Caledonie International": "SB",
    "Air Canada Jazz": "QK",
    "Air Canada": "AC",
    "Air Cargo Carriers": "2Q",
    "Air Central": "NV",
    "Air China": "CA",
    "Air City": "4F",
    "Air Class Líneas Aéreas": "QD",
    "Air Corridor": "QC",
    "Air Dolomiti": "EN",
    "Air Europa": "UX",
    "Air France": "AF",
    "Air Gabon": "GN",
    "Air Georgia": "DA",
    "Air Georgian": "ZX",
    "Air Guinee Express": "2U",
    "Air Guyane Express": "3S",
    "Air Hong Kong": "LD",
    "Air Iceland": "NY",
    "Air India Express": "IX",
    "Air India Limited": "AI",
    "Air Ivoire": "VU",
    "Air Japan": "NQ",
    "Air Kiribati": "4A",
    "Air Koryo": "JS",
    "Air Luxor GB": "L8",
    "Air Luxor": "LK",
    "Air Macau": "NX",
    "Air Madagascar": "MD",
    "Air Malawi": "QM",
    "Air Malta": "KM",
    "Air Marshall Islands": "CW",
    "Air Mauritanie": "MR",
    "Air Mauritius": "MK",
    "Air Midwest": "ZV",
    "Air Mobility Command": "MC",
    "Air Moldova": "9U",
    "Air Namibia": "SW",
    "Air Niugini": "PX",
    "Air North Charter - Canada": "4N",
    "Air Nostrum": "YW",
    "Air One": "AP",
    "Air Philippines": "2P",
    "Air Plus Comet": "A7",
    "Air Rarotonga": "GZ",
    "Air Sahara": "S2",
    "Air Saint Pierre": "PJ",
    "Air Salone": "2O",
    "Air Santo Domingo": "EX",
    "Air Serbia": "JU",
    "Air Seychelles": "HM",
    "Air Sinai": "4D",
    "Air Slovakia": "GM",
    "Air Southwest": "SZ",
    "Air São Tomé and Príncipe": "KY",
    "Air Tahiti Nui": "TN",
    "Air Tahiti": "VT",
    "Air Tanzania": "TC",
    "Air Tindi": "8T",
    "Air Togo": "YT",
    "Air Transat": "TS",
    "Air Turks and Caicos": "JY",
    "Air Ukraine": "6U",
    "Air Urga": "3N",
    "Air Vanuatu": "NF",
    "Air VIA": "VL",
    "Air Volga": "G6",
    "Air Wales": "6G",
    "Air Wisconsin": "ZW",
    "Air Zimbabwe": "UM",
    "AirAsia India": "I5",
    "AIRDO": "HD",
    "Aires, Aerovías de Integración Regional, S.A.": "4C",
    "Airlines of Tasmania": "FO",
    "Airlines PNG": "CG",
    "Airlink Zambia": "K8",
    "Airshop": "FF",
    "Airstars": "PL",
    "AirTran Airways": "FL",
    "Alaska Airlines, Inc.": "AS",
    "Alaska Central Express": "KO",
    "Albanian Airlines": "LV",
    "Albarka Air": "F4",
    "Alidaunia": "D4",
    "Alitalia": "AZ",
    "All Nippon Airways": "NH",
    "Allegiant Air": "G4",
    "Allegro": "LL",
    "Aloha Air": "KH",
    "Alpi Eagles": "E8",
    "Alpine Air Express": "5A",
    "Alrosa Air Company": "6R",
    "Amadeus IT Group": "1A",
    "Amber Air": "0A",
    "American Airlines": "AA",
    "American Eagle Airlines": "MQ",
    "Amerijet International": "M6",
    "Ansett Australia": "AN",
    "Antrak Air": "O4",
    "Ariana Afghan Airlines": "FG",
    "Arkia Israel Airlines": "IZ",
    "Armavia": "U8",
    "Armenian International Airways": "MV",
    "Aryan Cargo Express": "YE",
    "Aserca Airlines": "R7",
    "Asia Overnight Express": "OE",
    "Asian Spirit": "6K",
    "Astar Air": "ER",
    "Astraeus": "5W",
    "ATA Airlines": "TZ",
    "Atlant-Soyuz Airlines": "3G",
    "Atlantic Airways": "RC",
    "Atlas Air": "5Y",
    "Atlas Blue": "8A",
    "Atlasjet": "KK",
    "Atyrau Air Ways": "IP",
    "Augsburg Airways": "IQ",
    "Austral Líneas Aéreas": "AU",
    "Australian Airlines": "AO",
    "Austrian Airlines": "OS",
    "AVCOM": "J6",
    "Aviacsa": "6A",
    "Aviaexpress": "RX",
    "Avialeasing Aviation Company": "EC",
    "Avianca - Aerovías del Continente Americano S.A.": "AV",
    "Avianca Argentina": "A0",
    "Avianca Brazil": "O6",
    "Avient Aviation": "Z3",
    "Avies": "U3",
    "Avion Express": "X9",
    "Avirex": "G2",
    "Azerbaijan Airlines": "J2",
    "Azul Linhas Aéreas Brasileiras": "AD",
    "Bahamasair": "UP",
    "Balkan Bulgarian Airlines": "LZ",
    "Baltia Air Lines": "BQ",
    "Bangkok Airways": "PG",
    "Bankair": "B4",
    "Barents AirLink": "8N",
    "Batavia Air": "Y6",
    "Bearskin Lake Air Service": "JV",
    "Beijing Capital Airlines": "JD",
    "Belair Airlines": "4T",
    "Belavia Belarusian Airlines": "B2",
    "Bellview Airlines": "B3",
    "Bemidji Airlines": "CH",
    "Benin Golf Air": "A8",
    "Bering Air": "8E",
    "Berjaya Air": "J8",
    "BH Air": "8H",
    "Big Sky Airlines": "GQ",
    "Biman Bangladesh Airlines": "BG",
    "Binter Canarias": "NT",
    "Blue Air": "0B",
    "Blue Panorama Airlines": "BV",
    "Blue Wings": "QW",
    "Blue1": "KF",
    "Bluebird": "BF",
    "BMI Regional": "BM",
    "BMI": "BD",
    "Borajet": "YB",
    "Boston-Maine Airways": "E9",
    "Bouraq Indonesia Airlines": "BO",
    "BRA-Transportes Aéreos": "7R",
    "Bringer Air Cargo Taxi Aéreo": "E6",
    "Brit Air": "DB",
    "British Airways": "BA",
    "British International Helicopters": "BS",
    "British Mediterranean Airways": "KJ",
    "Brussels Airlines": "SN",
    "Bulgaria Air": "FB",
    "Bulgarian Air Charter": "1T",
    "Buryat Airlines Aircompany": "BU",
    "C.A.I. Second": "VE",
    "CAL Cargo Air Lines": "5C",
    "Calm Air": "MO",
    "Camai Air": "R9",
    "Cameroon Airlines": "UY",
    "Canadian North": "5T",
    "Canadian Western Airlines": "W2",
    "CanJet": "C6",
    "Cape Air": "9K",
    "Cargo 360": "GG",
    "Cargoitalia": "2G",
    "Cargojet Airways": "W8",
    "Cargolux": "CV",
    "Caribbean Airlines": "BW",
    "Caribbean Star Airlines": "8B",
    "Carpatair": "V3",
    "Caspian Airlines": "RV",
    "Cathay Pacific": "CX",
    "Cayman Airways": "KX",
    "Cebu Pacific": "5J",
    "Central Connect Airlines": "3B",
    "Central Mountain Air": "9M",
    "Chalk's International Airlines": "OP",
    "Champion Air": "MG",
    "Chang An Airlines": "2Z",
    "Chicago Express Airlines": "C8",
    "China Airlines": "CI",
    "China Cargo Airlines": "CK",
    "China Eastern Airlines": "MU",
    "China Express Airlines": "G5",
    "China Postal Airlines": "8Y",
    "China Southern Airlines": "CZ",
    "China United Airlines": "KN",
    "China Yunnan Airlines": "3Q",
    "Chitaavia": "X7",
    "Cielos Airlines": "A2",
    "Cimber Sterling": "QI",
    "Cirrus Airlines": "C9",
    "City Airline": "CF",
    "CityFlyer Express": "CJ",
    "CityJet": "WX",
    "Civil Air Transport": "CT",
    "Clickair": "XG",
    "Club Air": "6P",
    "Coast Air": "BX",
    "Coastal Air": "DQ",
    "Colgan Air": "9L",
    "Cologne Air Transport GmbH": "YD",
    "Comair": "MN",
    "CommutAir": "C5",
    "Comores Airlines": "KR",
    "Compass Airlines": "CP",
    "Condor Flugdienst": "DE",
    "Continental Express": "CO",
    "Continental Micronesia": "CS",
    "Conviasa": "V0",
    "Copa Airlines": "CM",
    "Corendon Airlines": "XC",
    "Corendon Dutch Airlines": "CD",
    "Corsairfly": "SS",
    "Corse Méditerranée": "XK",
    "Cosmic Air": "F5",
    "Croatia Airlines": "OU",
    "Crossair Europe": "QE",
    "Cubana de Aviación": "CU",
    "Cyprus Airways": "CY",
    "Cyprus Turkish Airlines": "YK",
    "Czech Airlines": "OK",
    "Daallo Airlines": "D3",
    "Dalavia": "H8",
    "Darwin Airline": "0D",
    "DAT Danish Air Transport": "DX",
    "Dauair": "D5",
    "Dba": "DI",
    "Delta Air Lines": "DL",
    "Deutsche Bahn": "2A",
    "DHL International": "ES",
    "Dirgantara Air Service": "AW",
    "Dniproavia": "Z6",
    "Dominair": "YU",
    "Dominicana de Aviación": "DO",
    "Domodedovo Airlines": "E3",
    "Donavia": "D9",
    "DonbassAero": "5D",
    "Dragonair, Hong Kong Dragon Airlines": "KA",
    "Druk Air": "KB",
    "Eagle Air": "H7",
    "Eagle Airways": "NZ",
    "Eagle Express Air Charter": "9A",
    "East African Safari Air": "S9",
    "Eastern Airways": "T3",
    "Eastland Air": "DK",
    "Eastwind Airlines": "W9",
    "easyJet Switzerland": "DS",
    "easyJet UK": "U2",
    "Edelweiss Air": "WK",
    "Egyptair": "MS",
    "El Al Israel Airlines": "LY",
    "El-Buraq Air Transport": "UZ",
    "Electronic Data Systems": "1Y",
    "Ellinair": "EL",
    "Emirates Airlines": "EK",
    "Empire Airlines": "EM",
    "Empresa Ecuatoriana De Aviación": "EU",
    "Eos Airlines": "E0",
    "Eritrean Airlines": "B8",
    "Ethiopian Airlines": "ET",
    "Etihad Airways": "EY",
    "Euro Exec Express": "RZ",
    "Eurocypria Airlines": "UI",
    "Eurofly": "GJ",
    "Eurolot": "K2",
    "Euromanx Airways": "3W",
    "Europe Airpost": "5O",
    "European Air Express": "EA",
    "European Air Transport": "QY",
    "European Aviation Air Charter": "E7",
    "Eurowings": "EW",
    "EVA Air": "BR",
    "Everbread": "E1",
    "Excel Airways": "JN",
    "Executive Airlines": "OW",
    "Express Air": "7A",
    "ExpressJet": "EV",
    "EZD": "Z2",
    "Far Eastern Air Transport": "EF",
    "FaroeJet": "F6",
    "Faso Airways": "F3",
    "Federal Express": "FX",
    "Fiji Airways": "FJ",
    "Finalair Congo": "4S",
    "Finnair": "AY",
    "Finncomm Airlines": "FC",
    "First Air": "7F",
    "First Choice Airways": "DP",
    "Fischer Air": "8F",
    "Flightline": "B5",
    "Florida West International Airways": "RF",
    "Fly Air": "F2",
    "Fly Georgia": "9Y",
    "Fly Me Sweden": "SH",
    "Fly One": "5F",
    "FlyAsianXpress": "D7",
    "Flybaboo": "F7",
    "Flybe": "BE",
    "Flydubai": "FZ",
    "Flyglobespan": "Y2",
    "FlyNordic": "LF",
    "Four Star Aviation": "HK",
    "Freedom Air": "SJ",
    "Frontier Airlines": "F9",
    "Frontier Flying Service": "2F",
    "Futura International Airways": "FH",
    "Galaxy Air": "7O",
    "Galileo International": "1G",
    "Gambia International Airlines": "GC",
    "Garuda Indonesia": "GA",
    "Gazpromavia": "4G",
    "GB Airways": "GT",
    "Gemini Air": "GR",
    "Georgian Airways": "A9",
    "Georgian National Airlines": "QB",
    "Germania": "ST",
    "Germanwings": "4U",
    "Ghana Airways": "GH",
    "Ghana International Airlines": "G0",
    "GMG Airlines": "Z5",
    "GoJet Airlines": "G7",
    "Gol Transportes Aéreos": "G3",
    "Golden Air": "DC",
    "Gorkha Airlines": "G1",
    "Great Lakes Airlines": "ZK",
    "Gujarat Airways": "G8",
    "Gulf Air Bahrain": "GF",
    "Hageland Aviation Services": "H6",
    "Hahn Air": "HR",
    "Hainan Airlines": "HU",
    "Hainan Phoenix Information Systems": "1R",
    "Haiti Ambassador Airlines": "2T",
    "Hamburg International": "4R",
    "Hapag-Lloyd Express (TUIfly)": "X3",
    "Hapagfly": "HF",
    "Harbor Airlines": "HB",
    "Hawaiian Airlines": "HA",
    "Hawkair": "BH",
    "Heavylift Cargo Airlines": "HN",
    "Helijet": "JB",
    "Helios Airways": "ZU",
    "Hellas Jet": "HJ",
    "Helvetic Airways": "2L",
    "Hewa Bora Airways": "EO",
    "Hex'Air": "UD",
    "Hi Fly": "5K",
    "Hong Kong Airlines": "HX",
    "Hong Kong Express Airways": "UO",
    "Hongtu Airlines": "A6",
    "Hop!": "A5",
    "Hope Air": "HH",
    "Horizon Air": "QX",
    "Horizon Airlines": "BN",
    "IBC Airways": "II",
    "Iberia Airlines": "IB",
    "Iberia Express": "I2",
    "Iberworld": "TY",
    "Ibex Airlines": "FW",
    "IBL Aviation": "0C",
    "Iceland Express": "HC",
    "Icelandair": "FI",
    "Iliamna Air Taxi": "V8",
    "Independence Air": "DH",
    "Independent Carrier (ICAR)": "C3",
    "Indian Airlines": "IC",
    "IndiGo Airlines": "6E",
    "Indigo Airlines": "I9",
    "Indonesia AirAsia": "QZ",
    "Indonesian Airlines": "IO",
    "INFINI Travel Information": "1F",
    "Inter Island Airways": "H4",
    "Interair South Africa": "D6",
    "Interavia Airlines": "ZA",
    "Interjet": "4O",
    "Interlink Airlines": "ID",
    "International Business Air": "6I",
    "Intersky": "3L",
    "Interstate Airlines": "I4",
    "Iran Air": "IR",
    "Iran Aseman Airlines": "EP",
    "Iraqi Airways": "IA",
    "Irtysh Air": "IH",
    "Islas Airways": "IF",
    "Islena De Inversiones": "WC",
    "Israir": "6H",
    "Itek Air": "GI",
    "Izair": "H9",
    "J-Air": "XM",
    "JAL Express": "JC",
    "Japan Air Commuter": "3X",
    "Japan Airlines Domestic": "JL",
    "Japan Asia Airways": "EG",
    "Japan Transocean Air": "NU",
    "Jazeera Airways": "J9",
    "Jeju Air": "7C",
    "Jet Airways": "QJ",
    "Jet Aviation": "PP",
    "Jet2.com": "LS",
    "JetBlue Airways": "B6",
    "Jetclub": "0J",
    "JetSMART": "JA",
    "Jetstar Airways": "JQ",
    "Jetstar Asia Airways": "3K",
    "Jetstar Hong Kong Airways": "JM",
    "JetStar Japan": "GK",
    "Jett8 Airlines": "JX",
    "Jettime": "JO",
    "Jordan International Air": "J4",
    "JSC Transport Automated Information Systems": "1M",
    "Juneyao Airlines": "HO",
    "Kabo Air": "N2",
    "Kalitta Air": "K4",
    "Kam Air": "RQ",
    "Kavminvodyavia": "KV",
    "KD Avia": "KD",
    "Keewatin Air": "FK",
    "Kenmore Air": "M5",
    "Kenya Airways": "KQ",
    "Keystone Air Service": "BZ",
    "Khalifa Airways": "K6",
    "Kish Air": "Y9",
    "Kiwi International Air Lines": "KP",
    "KLM": "KL",
    "Kogalymavia Air Company": "7K",
    "Komiinteravia": "8J",
    "Korean Air": "KE",
    "Krasnojarsky Airlines": "7B",
    "Krylo Airlines": "K9",
    "Kuwait Airways": "KU",
    "Kuzu Airlines": "GO",
    "Kyrgyzstan Airlines": "R8",
    "Kyrgyzstan": "QH",
    "L.A.B. Flying Service": "JF",
    "LACSA": "LR",
    "LAI - Línea Aérea IAACA": "KG",
    "Lankair": "IK",
    "Lao Airlines": "QV",
    "LATAM Argentina": "4M",
    "LATAM Brasil": "JJ",
    "LATAM Chile": "LA",
    "LATAM Express": "LU",
    "LATAM Peru": "LP",
    "Lauda Air": "NG",
    "Lebanese Air Transport": "LQ",
    "Leeward Islands Air Transport": "LI",
    "Libyan Arab Airlines": "LN",
    "Linear Air": "O2",
    "Linhas Aéreas de Moçambique": "TM",
    "Lion Mentari Airlines": "JT",
    "Livingston": "LM",
    "Lloyd Aéreo Boliviano": "LB",
    "LongJiang Airlines": "LT",
    "LOT Polish Airlines": "LO",
    "LTE International Airways": "XO",
    "LTU Austria": "L3",
    "Luftfahrtgesellschaft Walter": "HE",
    "Lufthansa Airlines": "LH",
    "Lufthansa CityLine": "CL",
    "Lufthansa Systems": "L1",
    "Lufttransport": "L5",
    "Luxair": "LG",
    "Lviv Airlines": "5V",
    "Lynden Air": "L2",
    "Lynx Aviation": "L4",
    "Línea Aérea Amaszonas": "Z8",
    "Línea Aérea de Servicio Ejecutivo Regional": "QL",
    "Línea Aérea SAPSA": "L7",
    "Líneas Aéreas Azteca": "ZE",
    "Macair Airlines": "CC",
    "Maersk": "DM",
    "Mahan Air": "W5",
    "Malaysia Airlines": "MH",
    "Maldivian (airline)": "Q2",
    "Malindo Airways": "OD",
    "Malmö Aviation": "TF",
    "Malta Air Charter": "R5",
    "Malév Hungarian Airlines": "MA",
    "Mandala Airlines": "RI",
    "Mandarin Airlines": "AE",
    "Mango": "JE",
    "MAP-Management and Planung": "AQ",
    "Mars RK": "6V",
    "Martinair": "MP",
    "Mavial Magadan Airlines": "H5",
    "Mayair": "7M",
    "Mekong Airlines": "M8",
    "Menajet": "IM",
    "Meridiana": "IG",
    "Merpati Nusantara Airlines": "MZ",
    "Mesa Airlines": "YV",
    "Mexicana de Aviación": "MX",
    "MHS Aviation GmbH": "M2",
    "Miami Air International": "GL",
    "MIAT Mongolian Airlines": "OM",
    "Middle East Airlines": "ME",
    "Midway Airlines (1976–1991)": "ML",
    "Midway Airlines (1993–2003)": "JI",
    "Midwest Airlines (Egypt)": "MY",
    "Midwest Airlines": "YX",
    "Mihin Lanka": "MJ",
    "Mistral Air": "M4",
    "MNG Airlines": "MB",
    "Mokulele Airlines": "MW",
    "Moldavian Airlines": "2M",
    "Monarch Airlines": "ZB",
    "Montenegro Airlines": "YM",
    "Moskovia Airlines": "3R",
    "Motor Sich": "M9",
    "Mount Cook Airlines": "NM",
    "Myanma Airways": "UB",
    "Myanmar Airways International": "8M",
    "Myway Airlines": "8I",
    "NAM Air": "IN",
    "Nantucket Airlines": "DV",
    "National Air dba National Airlines": "N8",
    "National Airlines": "N7",
    "National Airways Cameroon": "9O",
    "Nationwide Airlines": "CE",
    "Navitaire": "1N",
    "Neos": "NO",
    "Nepal Airlines": "RA",
    "New England Airlines": "EJ",
    "Niki": "HG",
    "Nippon Cargo Airlines": "KZ",
    "Nok Air": "DD",
    "Nordavia": "5N",
    "Nordeste Linhas Aéreas Regionais": "JH",
    "Nordic Regional": "6N",
    "Nordwind Airlines": "N4",
    "North American Airlines": "NA",
    "North Coast Aviation": "N9",
    "North Flying": "M3",
    "North-Wright Airways": "HW",
    "Northern Air": "NC",
    "Northwest Airlines": "NW",
    "Northwest Regional Airlines": "FY",
    "Northwestern Air": "J3",
    "Norwegian Air International": "D8",
    "Norwegian Air Shuttle": "DY",
    "Norwegian Long Haul": "DU",
    "Nouvel Air Tunisie": "BJ",
    "Nova Airline": "O9",
    "Novo Air": "VQ",
    "Nuevo Continente": "N6",
    "O'Connor Airlines": "UQ",
    "Oasis Hong Kong Airlines": "O8",
    "Oasis International Airlines": "OB",
    "OLT Express Germany": "OL",
    "Olympic Airlines": "OA",
    "Oman Air": "WY",
    "Omni Air International": "OY",
    "Open Skies Consultative Commission": "1L",
    "Orenburg Airlines": "R2",
    "Orient Thai Airlines": "OX",
    "Origin Pacific Airways": "QO",
    "Our Airline": "ON",
    "Overland Airways": "OJ",
    "Ozark Air Lines": "OZ",
    "Ozjet Airlines": "O7",
    "Pace Airlines": "Y5",
    "Pacific Airlines": "BL",
    "Pacific Coastal Airlines": "8P",
    "Pacific East Asia Cargo Airlines": "Q8",
    "Pacificair": "GX",
    "Pakistan International Airlines": "PK",
    "Palau Trans Pacific Airlines": "GP",
    "Palestinian Airlines": "PF",
    "Pamir Airways": "NR",
    "Pan American Airways": "PN",
    "Pan American World Airways": "PA",
    "Pantanal Linhas Aéreas": "P8",
    "Paramount Airways": "I7",
    "Passaredo Transportes Aéreos": "P3",
    "PAWA Dominicana": "7N",
    "PB Air": "9Q",
    "Pegasus Airlines": "PC",
    "Peninsula Airways": "KS",
    "People's Viennaline": "PE",
    "Peruvian Airlines": "P9",
    "Philippine Airlines": "PR",
    "Phoenix Airways": "HP",
    "Phuket Air": "9R",
    "Pinnacle Airlines": "9E",
    "PLUNA": "PU",
    "PMTair": "U4",
    "Polar Air": "PO",
    "Polynesian Airlines": "PH",
    "Polynesian Blue": "DJ",
    "Polyot Sirena": "1U",
    "Porter Airlines": "PD",
    "Portugalia": "NI",
    "Potomac Air": "BK",
    "Precision Air": "PW",
    "Primaris Airlines": "FE",
    "Princess Air": "8Q",
    "Private Wings Flugcharter": "8W",
    "Privilege Style Líneas Aéreas": "P6",
    "Proflight Zambia": "P0",
    "PSA Airlines": "OH",
    "Qatar Airways": "QR",
    "RACSA": "R6",
    "Radixx Solutions International": "1D",
    "Redhill Aviation": "8L",
    "Regional Air Lines": "FN",
    "Regional Express": "ZL",
    "RegionsAir": "3C",
    "Reno Air": "QQ",
    "Republic Airlines": "RW",
    "Republic Express Airlines": "RH",
    "Rico Linhas Aéreas": "C7",
    "Rio Grande Air": "E2",
    "Rossiya": "FV",
    "Royal Air Force": "RR",
    "Royal Air Maroc": "AT",
    "Royal Airlines": "R0",
    "Royal Aruban Airlines": "V5",
    "Royal Brunei Airlines": "BI",
    "Royal Jordanian": "RJ",
    "Royal Khmer Airlines": "RK",
    "Royal Phnom Penh Airways": "RL",
    "Russian Sky Airlines": "P7",
    "Rwandair Express": "WB",
    // "Ryan Air Service": "7S",
    "Ryan International Airlines": "RD",
    "Ryanair": "FR",
    "Régional Compagnie Aérienne Européenne": "YS",
    "S7 Airlines": "S7",
    "Sabre Pacific": "1Z",
    "Sabre": "1S",
    "SAETA": "EH",
    "Safair": "FA",
    "SalamAir": "OV",
    "SAM Colombia": "MM",
    "Sama Airlines": "ZS",
    "Samara Airlines": "E5",
    "Santa Barbara Airlines": "S3",
    "Saratov Airlines Joint Stock Company": "6W",
    "SAS Braathens": "SK",
    "Sat Airlines": "HZ",
    "SATA Air Acores": "SP",
    "SATA International": "S4",
    "Satgur Air Transport": "2S",
    "Saudia": "SV",
    "Scorpio Aviation": "8S",
    "Seaborne Airlines": "BB",
    "SeaPort Airlines": "K5",
    "Servant Air": "8D",
    "Servicios Aéreos de los Andes": "FP",
    "Servicios de Transportes Aéreos Fueguinos": "FS",
    "Severstal Air Company": "D2",
    "SF Airlines": "O3",
    "Shaheen Air International": "NL",
    "Shandong Airlines": "SC",
    "Shanghai Airlines": "FM",
    "Shanxi Airlines": "8C",
    "Shenzhen Airlines": "ZH",
    "Sibaviatrans": "5M",
    "Sichuan Airlines": "3U",
    "Siem Reap Airways": "FT",
    "Sierra National Airlines": "LJ",
    "Silk Way Airlines": "ZP",
    "SilkAir": "MI",
    "Singapore Airlines": "SQ",
    "Siren-Travel": "1H",
    "Sirena": "1Q",
    "Sirin": "R1",
    "SITA": "XS",
    "Skagway Air Service": "N5",
    "Sky Airline": "H2",
    "Sky Airlines": "ZY",
    "Sky Eyes": "I6",
    "Sky Regional Airlines": "RS",
    "Sky Trek International Airlines": "1I",
    "Skybus Airlines": "SX",
    "SkyEurope": "NE",
    "SkyGreece Airlines": "GW",
    "SkyKing Turks and Caicos Airways": "RU",
    "Skymark Airlines": "BC",
    "Skynet Airlines": "SI",
    "Skynet Asia Airways": "6J",
    "Skyservice Airlines": "5G",
    "SkyStar Airways": "XT",
    "Skytaxi": "TE",
    "Skyways Express": "JZ",
    "SkyWest Airlines": "OO",
    "Skywise Airline": "S8",
    "Slok Air Gambia": "S0",
    "Slovak Airlines": "6Q",
    "Smartlynx Airlines": "6Y",
    "SNCF": "2C",
    "Solomon Airlines": "IE",
    "South African Airways": "SA",
    "Southern Winds Airlines": "A4",
    "Southwest Airlines": "WN",
    "Spanair": "JK",
    "Spicejet": "SG",
    "Spirit Airlines": "NK",
    "Spring Airlines Japan": "IJ",
    "Spring Airlines": "9C",
    "SriLankan Airlines": "UL",
    "St Barth Commuter": "PV",
    "Star Air": "S6",
    "Star Flyer": "7G",
    "Star1 Airlines": "V9",
    "Starlink Aviation": "Q4",
    "Sterling Airlines": "NB",
    "Stobart Air": "RE",
    "Sudan Airways": "SD",
    "Sun Air (Fiji)": "PI",
    "Sun Air International": "LW",
    "Sun Air of Scandinavia": "EZ",
    "Sun Country Airlines": "SY",
    "Sun D'Or": "7L",
    "SunExpress": "XQ",
    "Sunshine Airlines": "SO",
    "Sunshine Express Airlines": "CQ",
    "Sunstate Airlines": "QF",
    "Sunwing Airlines": "WG",
    "Surinam Airways": "PY",
    "Sutra": "1K",
    "Swe Fly": "WV",
    "SwedJet Airways": "VD",
    "Swedline Express": "SM",
    "Swiss International Air Lines": "LX",
    "Swissair": "SR",
    "Switfair": "W3",
    "Sylt Air GmbH": "7E",
    "Syrian Arab Airlines": "RB",
    "T'way Air": "TW",
    "TAAG Angola Airlines": "DT",
    "TACV": "VR",
    "TAM Mercosur": "PZ",
    "TAME": "EQ",
    "TAMPA": "QT",
    "Tandem Aero": "TQ",
    "TAP Portugal": "TP",
    "Taquan Air Services": "K3",
    "TAR Aerolineas": "YQ",
    "Tarom": "RO",
    "Tassili Airlines": "SF",
    "Tavrey Airlines": "T6",
    "Tbilaviamsheni": "L6",
    "Teamline Air": "L9",
    "Thai Air": "T2",
    "Thai AirAsia X": "XJ",
    "Thai AirAsia": "FD",
    "Thai Airways International": "TG",
    "Thai Lion Mentari": "SL",
    "Thai Sky Airlines": "9I",
    "Thai Smile Airways": "WE",
    "Thai Star Airlines": "T9",
    "Thai Vietjet Air": "VZ",
    "Thalys": "2H",
    "Thomas Cook Airlines": "MT",
    "Thomson Airways": "BY",
    "Tianjin Airlines": "GS",
    "Tiara Air": "3P",
    "Tigerair Australia": "TT",
    "Tigerair Philippines": "DG",
    "Tigerair Singapore": "TR",
    "Tigerair Taiwan": "IT",
    "Titan Airways": "ZT",
    "TNT Airways": "3V",
    "Tobruk Air": "7T",
    "Tol-Air Services": "TI",
    "Tradewind Aviation": "TJ",
    "Trans Mediterranean Airlines": "TL",
    "Trans States Airlines": "AX",
    "Transaero Airlines": "UN",
    "TransAsia Airways": "GE",
    "Transavia France": "TO",
    "Transavia Holland": "HV",
    "TransAVIAexport Airlines": "AL",
    "Transeuropean Airlines": "UE",
    "Transmile Air Services": "TH",
    "Transportes Aéreos Nacionales": "TX",
    "Transports et Travaux Aériens de Madagascar": "OF",
    "Transwest Air": "9T",
    "Trast Aero": "S5",
    "Travel Service": "QS",
    "Travelsky Technology": "1E",
    "Tri-MG Intra Asia Airlines": "GY",
    "TRIP Linhas Aéreas": "T4",
    "Tropic Air": "PM",
    "Tropical Airways": "M7",
    "TUI Airlines Netherlands": "OR",
    "TUIfly Nordic": "6B",
    "Tulip Air": "TD",
    "Tuninter": "UG",
    "Tunisair": "TU",
    "Turan Air": "3T",
    "Turkish Airlines": "TK",
    "Turkmenhovayollary": "T5",
    "Twin Jet": "T7",
    "Tyrolean Airways": "VO",
    "Uganda Airlines": "QU",
    "Ukraine International Airlines": "PS",
    "Ukrainian Cargo Airways": "6Z",
    "UM Airlines": "UF",
    "United Airlines": "UA",
    "United Airways": "4H",
    "United Feeder Service": "U2",
    "United Parcel Service": "5X",
    "Ural Airlines": "U6",
    "US Airways": "US",
    "US Helicopter": "UH",
    "USA Jet Airlines": "U7",
    "USA3000 Airlines": "U5",
    "UTair Aviation": "UT",
    "Uzbekistan Airways": "HY",
    "Valuair": "VF",
    "ValuJet Airlines": "J7",
    "Vanilla Air": "JW",
    "Varig Logística": "LC",
    "VASP": "VP",
    "Via Rail Canada": "2R",
    "Viaggio Air": "VM",
    "Viasa": "VA",
    "Vieques Air Link": "V4",
    "Vietjet Air": "VJ",
    "Vietnam Air Services Company (VASCO)": "0V",
    "Vietnam Airlines": "VN",
    "VIM Airlines": "NN",
    "Vipair Airlines": "9V",
    "Virgin America": "VX",
    "Virgin Atlantic Airways": "VS",
    "Virgin Express": "TV",
    "Virgin Nigeria Airways": "VK",
    "Vision Airlines": "V2",
    "VIVA Aerobus": "VB",
    "Viva Macau": "ZG",
    "VivaColombia": "5Z",
    "Vladivostok Air": "XF",
    "VLM Airlines": "VG",
    "Volaris": "Y4",
    "Volga-Dnepr Airlines": "VI",
    "Volotea": "V7",
    "Voyageur Airways": "VC",
    "VRG Linhas Aéreas": "RG",
    "Vueling Airlines": "VY",
    "Wasaya Airways": "WT",
    "Wataniya Airways": "KW",
    "WDL Aviation": "WD",
    "WebJet Linhas Aéreas": "WH",
    "Welcome Air": "2W",
    "West African Airlines": "WZ",
    "West Air Sweden": "PT",
    "West Caribbean Airways": "YH",
    "West Coast Air": "8O",
    "Western Airlines": "WA",
    "Western Pacific Airlines": "W7",
    "WestJet Encore": "WR",
    "WestJet": "WS",
    "Westward Airways": "CN",
    "Widerøe": "WF",
    "Wind Jet": "IV",
    "Windrose Air": "7W",
    "Wings Air": "IW",
    "Wizz Air Bulgaria": "8Z",
    "Wizz Air": "W6",
    "World Airways": "WO",
    "Worldspan": "1P",
    "WOW air": "WW",
    "Wright Air Service": "8V",
    "Xiamen Airlines": "MF",
    "XL Airways France": "SE",
    "XpressAir": "XN",
    "Xtra Airways": "XP",
    "Yakutia Airlines": "R3",
    "Yamal Airlines": "YL",
    "Yangtze River Express": "Y8",
    "Yellow Air Taxi/Friendship Airways": "Y0",
    "Yemenia": "IY",
    "Yute Air Alaska": "4Y",
    "Yuzhmashavia": "2N",
    "Zambian Airways": "Q3",
    "Zimex Aviation": "C4",
    "Zip": "3J",
    "Zoom Airlines": "Z4"
  }

  var airlineMap = []

  Object.keys(jsonAirlines).forEach((key1)=>{
    airlineMap.push({label:key1, value:key1})
  });




  return (
    <FormGroup {...formGroupProps}>
      <Select
        id={id}
        //value={value || ''}
        onBlur={() => setIsTouched(true)}
        aria-invalid={showError}
        aria-describedby={!isValid ? `${id}-error` : undefined}
        placeholder={placeholder}
        onChange={(e) => setValue(e.value)}
        options = {airlineMap}
      >
      </Select >
      {children}
    </FormGroup >
  );
};
