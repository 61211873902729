import React from 'react';
import {
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverHeader,
  PopoverBody,
  PopoverFooter,
  PopoverArrow,
  PopoverCloseButton,
  PopoverAnchor,
  Button, Heading, Link, Box, Stack
} from '@chakra-ui/react';
import { ExternalLinkIcon, StarIcon } from '@chakra-ui/icons';
import { useForm } from '@formiz/core';

export const PageHeader = ({ children, onReset = () => { } }) => {
  const form = useForm({ subscribe: false });

  return (
    <Box data-test="header">
      {/* <Heading d="flex" alignItems="center">
        {children}
      </Heading> */}
      <Popover>
        <PopoverTrigger>
          <Link
            fontSize="sm"
          >
            More information
            <ExternalLinkIcon ml="1" mb="1" />
          </Link>
        </PopoverTrigger>
        <PopoverContent>
          <PopoverArrow />
          <PopoverCloseButton />
          <PopoverHeader>Our Process</PopoverHeader>
          <PopoverBody>
            <Stack>
              <Box><b>How much can I get paid with Airjust?</b></Box>
              <Box w={"100%"}>
              Passengers with an eligbile flight can recieve up to 550 pounds in compensation. Fill out the form to find out exactly how much you are eligible for.</Box>
              <Box><b>How does Airjust work?</b></Box>
              <Box w={"100%"}>
              We fight the airline for you to make sure you get the compensation you are owed, fill out the form and let us handle the rest.</Box>
                <Box><b>How much does Airjust cost?</b></Box>
              <Box w={"100%"}>
              Airjust is free to search your flight, if you submit your flight to us we will take care of everything, including depositing into your bank. If we sucessfuly claim compensation for you we take a small fee ranging from 15%-45% of money that is calimed. This money goes to paying legal council and Airjust team members who fight the airline for you.</Box>
              <Box><b>How long does it take to get paid?</b></Box>
              <Box w={"100%"}>
              As you know, airlines are hard to deal with, most claims take 1-2 months to fully resolve, but some can be shorter or longer. Our experienced team works to get the fastest payout for you.</Box>
            </Stack>

          </PopoverBody>
        </PopoverContent>
      </Popover>

    </Box>
  );
};
