import React, {useEffect} from 'react';
import { Formiz, useForm } from '@formiz/core';
import { isEmail } from '@formiz/validations';
import { Button, Flex, Box } from '@chakra-ui/react';
import { FieldInput } from '../components/Fields/FieldInput';
import { PageHeader } from '../components/PageHeader';
import { useToastValues } from '../hooks/useToastValues';
import { PageLayout } from '../layout/PageLayout';
import { FieldUpload } from '../components/Fields/FieldUpload';
import useUETTracking from '../useUETTracking';
import BingTracking from 'react-tracking-bing';
import ReactPixel from 'react-facebook-pixel';

export const SubmitTicket = () => {
  const form = useForm({ subscribe: 'form' });
  const options = {
    autoConfig: true,
    debug: false,
  };
  ReactPixel.init('1300921143958590', null, options);
  ReactPixel.pageView();
  const toastValues = useToastValues();
  useEffect(() => {
    window.gtag('config', 'G-83KZEHBVC0');
    window.gtag('config', 'UA-224099637-1');
  });
  const handleSubmit = (values) => {
    toastValues(values);
  };

  return (
    <>
    <Formiz connect={form} onValidSubmit={handleSubmit} autoForm>
        <PageHeader>Confirmation</PageHeader>
        <Box w='100%' pt={5} pb={5}>
          <p><b>Your information has been successfully entered into our system</b></p>
          <p>You could be eligible for compensation of £200 to £550.</p>
          <p> Our experienced team will review your claim and finish calcualting your total compensation amount.</p>
          <p>Please email any questions to help@airjust.co or visit our website airjust.co</p>
        </Box>
        {/* <FieldInput
          name="cancellationText"
          label="Reason Given by Airline for Delay/Cancellation"
          formatValue={(val) => (val || '').trim()}
        />
        <FieldInput
          name="additionalInfo"
          label="Additional Information"
          formatValue={(val) => (val || '').trim()}
        /> */}
        {/* <Flex>
          <Button
            type="submit"
            ml="auto"
            colorScheme="brand"
            isDisabled={
              (!form.isValid || form.isValidating) && form.isSubmitted
            }
          >
            Submit
          </Button>
        </Flex> */}
    </Formiz>
    </>
  );
};